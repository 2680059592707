.compare {
    @apply border-8;
    @apply border-gray-300;

    h4 {
        @apply text-center;
        @apply font-bold;
        @apply underline;
    }

    .row {
        @apply grid;
        @apply grid-cols-4;
        @apply gap-1;
        @apply ml-2;

        &.row-what {
            @screen lg {
                @apply h-32;
            }
            @screen xl {
                @apply h-28;
            }
            @screen 2xl {
                @apply h-20;
            }
        }
        &.row-how {
            @screen lg {
                @apply h-80;
            }
            @screen xl {
                @apply h-72;
            }
            @screen 2xl {
                @apply h-56;
            }
        }
        &.row-who {
            @screen lg {
                @apply h-48;
            }
            @screen xl {
                @apply h-40;
            }
            @screen 2xl {
                @apply h-32;
            }
        }
        &.row-length {
            @screen lg {
                @apply h-28;
            }
            @screen xl {
                @apply h-28;
            }
            @screen 2xl {
                @apply h-24;
            }
        }
        &.row-recover {
            @screen lg {
                @apply h-28;
            }
            @screen xl {
                @apply h-24;
            }
            @screen 2xl {
                @apply h-20;
            }
        }
        &.row-next {
            @screen lg {
                @apply h-40;
            }
            @screen xl {
                @apply h-28;
            }
            @screen 2xl {
                @apply h-24;
            }
        }
    }

    .title {
        @apply col-span-4;
        @apply bg-gray-100;
        @apply py-3;
        @apply mx-1;

        @screen lg {
            @apply h-12;
        }

        @screen xl {
            @apply h-full;
            @apply col-span-1;
        }

        span {
            @apply font-bold;
            @apply float-left;
            @apply w-full;
            @apply px-1;
            @apply ml-1;
        }
    }

    .detail {
        @apply my-2;
        @apply px-2;
    }

    .image-feature {
        @apply mt-8;

        .label {
            @apply text-center;
            @apply font-bold;
        }
    }

    &.compare-columns {
        .detail {
            @apply col-span-4;

            @screen xl {
                @apply col-span-3;
            }
        }
    }

    &.compare-icd {
        .detail {
            // @screen lg {
            //     height: 22rem;
            // }
            // @screen xl {
            //     @apply h-64;
            // }
            // @screen 2xl {
            //     @apply h-56;
            // }
        }
    }

    &.no-border {
        @apply border-0;
    }
}

.source {
    // @apply text-center;
    font-size: 0.85rem;
}

.consider {
    .compare {
        h4 {
            @apply h-12;

            @screen xl {
                @apply h-8;
            }
        }
        p {
            @apply mt-2;
        }
        .row {
            @apply grid;
            @apply grid-cols-4;
            @apply gap-1;
            @apply ml-2;

            &.row-what {
                @screen lg {
                    @apply h-20;
                }
                @screen xl {
                    @apply h-16;
                }
                @screen 2xl {
                    @apply h-12;
                }
            }
            &.row-how {
                @screen lg {
                    @apply h-48;
                }
                @screen xl {
                    @apply h-44;
                }
                @screen 2xl {
                    @apply h-40;
                }
            }
            &.row-who {
                @screen lg {
                    @apply h-36;
                }
                @screen xl {
                    @apply h-32;
                }
                @screen 2xl {
                    @apply h-28;
                }
            }
        }
        &.compare-icd {
            .detail {
                // @screen lg {
                //     height: 44rem;
                // }
                // @screen xl {
                //     height: 35rem;
                // }
                // @screen 2xl {
                //     height: 26rem;
                // }
            }
        }
    }
}
