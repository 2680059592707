@import './base';

body {
    height: 100%;
}

.consider {
    h1::after {
        border-color: $primary-red;
    }
    h3::after {
        border-color: $primary-red;
    }
    h5 {
        color: $primary-red;
    }
    .nav-wrapper {
        background-color: $primary-red;
    }
    .btn--primary {
        background-color: $primary-red;
    }
}

.print {
    display: none;
}

@media print {
    @page {
        margin-left: 0.5in;
        margin-right: 0.5in;
        margin-top: 0.5em;
        margin-bottom: 0;
    }

    .print-page-break {
        page-break-before: always;
    }
    .print {
        display: block !important;
    }
}