.radio-group {
    @apply inline-block;
    @apply text-center;
    @apply mx-2;

    label {
        @apply block;
    }

    @screen sm {
        @apply mx-3;
    }

    @screen md {
        @apply mx-4;
    }

    @screen 2xl {
        @apply mx-5;
    }
}

.question {
    @apply my-8;
}

.question-form {
    @apply row;
    @apply grid;
    @apply grid-cols-4;
    @apply mt-4;

    &.question-form-3 {
        @apply grid-cols-3;
    }

    .text {
        @apply mr-2;
        @apply col-span-2;

        &.short {
            @apply col-span-1;
        }

        // @screen sm {
        //     @apply col-span-3;
        // }
    }
    .number {
        @apply py-1;
        @apply px-2;
    }
    .textbox {
        @apply col-span-4;

        textarea {
            @apply text-sm;
        }
    }
    .radio {
        @apply ml-2;
        @apply col-span-2;

        &.short {
            @apply col-span-1;
        }

        &.long {
            @apply col-span-3;
        }

        // @screen sm {
        //     @apply col-span-1;
        // }
    }
}

.question-box {
    @apply border-4;
    @apply border-black;
    @apply bg-blue-100;
    @apply text-center;
    @apply max-w-xs;
    @apply mx-auto;
    @apply my-10;

    &.question-box-short {
        @apply my-2;
    }

    hr {
        @apply border-2;
        @apply border-blue-800;
        @apply my-4;
        @apply mx-auto;
        @apply text-center;
        @apply w-32;
    }

    .heading {
        @apply uppercase;
        @apply font-semibold;
        @apply pb-2;
    }

    .content {
        @apply px-4;
    }
}