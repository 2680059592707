/** Buttons **/
.btn-group {
    @apply mt-8;
    @apply text-center;
}

.btn {
    @apply flex-shrink-0;
    @apply text-white;
    @apply py-2;
    @apply px-3;
    @apply rounded-lg;
    @apply shadow-md;
}

.btn:hover {
    @apply text-white;
}

.btn:focus {
    @apply outline-none;
    @apply border-0;
}

.btn--primary {
    @apply opacity-75;
    @apply text-white;
    background-color: $primary-blue;
}

.btn--secondary {
    @apply text-black;
    @apply bg-gray-100;
}