h1 {
    @apply text-gray-900;
    @apply text-2xl;
    @apply font-medium;
    @apply mb-8;

    &.no-ul::after {
        @apply border-b-0;
    }

    @screen lg {
        @apply text-2xl;
    }

    @media print {
        @apply mt-4;
    }
}
h1::after {
    content: "";
    @apply block;
    @apply border-b-4;
    border-color: $primary-blue;
}
h2 {
    @apply text-gray-900;
    @apply font-bold;
}
h3 {
    @apply text-gray-900;
    @apply font-semibold;
    @apply text-lg;
    @apply inline-block;
    @apply my-4;
}
h3::after {
    content: "";
    @apply block;
    @apply border-b-2;
    border-color: $primary-blue;
}
h4 {
    @apply text-gray-900;
    @apply font-medium;
    @apply my-4;
}
h5 {
    @apply font-semibold;
    @apply my-8;
}

.section-heading {
    @apply font-bold;
    @apply mt-2;
}

.strong {
    @apply font-bold;
}