.padit-score-calculator {
    h5 {
        @apply mt-4;
        @apply mb-1;
    }
    .row {
        @apply pb-3;
        @apply border-b-2;
    }
    .radio-options {
        @apply print:hidden;
        @apply mx-4;
    }
    .radio-print {
        @apply italic;
        @apply mx-4;
        @apply hidden;
        @apply print:block;
    }
}