.image {
    @apply mx-10;

    img {
        max-width: 4em;
        @apply w-full;
        @apply mx-auto;

        &.image-sm {
            max-width: 10em;
        }
        &.image-md {
            max-width: 16em;
        }
        &.image-lg {
            @apply max-w-xs;
        }
    }
}

.logo-image {
    @apply mx-auto;
}

.fa-male {
    font-size: 1rem;
    @apply p-px;
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.fa-blue {
    --tw-text-opacity: 1;
    color: $primary-blue;
}

.fa-red {
    --tw-text-opacity: 1;
    color: $primary-red;
}