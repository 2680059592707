.row {
    @apply my-2;
}

.grid-container {
    @apply my-2;
    @apply grid;
    @apply grid-cols-1;
}

.grid-section {
    @apply my-2;
    @apply grid;
    @apply grid-cols-9;
    @apply border-t-4;
    @apply border-l-4;
    @apply border-r-2;
    @apply border-b-2;

    &.grid-narrow {
        @apply grid-cols-6;
    }
}

.grid-header {
    @apply text-center;
    @apply font-bold;
    @apply p-2;
}

.grid-left {
    @apply col-span-3;
}
.grid-middle {
    @apply col-span-3;
    @apply text-center;
}
.grid-right {
    @apply col-span-3;
    @apply text-center;
}

.grid-row {
    @apply border-r-2;
    @apply border-b-2;
    @apply p-2;
}

.grid-label {
    @apply text-center;
}

.grid-people {
    @apply pt-4;
    @apply px-2;

    @screen md {
        @apply px-10;
    }
}

.consider {
    .option-knowledge {
        .grid-left {
            @apply col-span-3
        }
        .grid-middle {
            @apply col-span-3;
            @apply text-center;
        }
        .grid-right {
            @apply col-span-3;
            @apply text-center;
        }

        .grid-narrow {
            .grid-left {
                @apply col-span-2;
            }
            .grid-right {
                @apply col-span-4;
                @apply text-left;
            }
        }

    }
}