@tailwind base;
@tailwind components;
@tailwind utilities;

@import './colours';
@import './navigation';
@import './headings';
@import './lists';
@import './grid';
@import './forms';
@import './images';
@import './buttons';