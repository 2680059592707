.nav-wrapper {
    background-color: $primary-blue;
    @apply text-blue-100;
    min-width: 390px;
    max-width: 390px;
    @apply space-y-6;
    @apply py-7;
    @apply absolute;
    @apply inset-y-0;
    @apply left-0;
    @apply transform;
    @apply transition;
    @apply duration-200;
    @apply ease-in-out;
    @apply z-10;

    @screen xl {
        @apply px-2;
    }
}

nav {
    @apply h-full;

    ul {
        @apply sticky;
        @apply h-full;
        @apply flex;
        @apply flex-col;
        @apply pl-0;

        :last-child {
            margin-top: auto;
        }
    }

    li.heading {
        @apply font-bold;
        @apply leading-6;
        @apply text-xl;
        @apply mt-2;
        @apply mb-4;
    }

    li a {
        @apply block;
        @apply py-1;
        @apply px-1;
        @apply rounded;
        @apply transition;
        @apply duration-200;
    }

    li a:hover {
        @apply opacity-90;
        @apply bg-transparent;
        @apply text-white;
        @apply no-underline;
    }

    li a.active {
        @apply underline;
    }

}

.content-wrapper {
    @apply w-full;
    @apply py-8;
    @apply bg-white;
    @apply px-4;

    @screen xl {
        @apply w-3/4;
        @apply px-16;
    }
}
